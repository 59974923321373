<template>
  <section>
     <servidorLista :pg="this.pg" :funcao="this.funcao" />
  </section>
</template>

<script>
import { defineComponent } from "vue";
import { Servidores } from "@/class/servidores.js";
import axios from 'axios';
import Util from "@/utils/rota";

import servidorLista from "@/components/servidores/servidorAll.vue"


export default defineComponent({
  props:{
    pg: {
      default:1
    },
  },
  components:{
    servidorLista
  },
  data() {
    return {
      funcao: 0,
    };
  },
  async  beforeMount() {
    const token = sessionStorage.getItem("token");

    const util = new Util();
    const data = await util.WhoIam(token);
    this.funcao = data.funcao;
  },
});
</script>

<style lang="scss" scoped>
.table-example--pagination {
  padding-top: 20px;
  text-align: center;
  text-align: -webkit-center;
}
</style>

<style>
 .va-list-item:hover{
   background-color: #f5f5f5;
 }
 .va-list-item__inner{
   padding-right: 30px;
 }
 .va-dropdown__content{
   padding: 0;
 }
 .form-control{
  background-color: #fff !important;
 }
</style>
